<template>
<div class="pb-52">
  <div class="cooperation">
    <div class="container pt-44 pb-28">
      <div class="row">
        <div class="col-md-12 col-lg-4 mb-6 lg:mb-0">
          <h2 class="text-ABC Optima text-orange font-bold" style="letter-spacing: .3rem;">Smart world</h2>
          <p class="font-bold mb-16 text-black" style="font-size:3.75rem">智能世界</p>
          <p class="w-80 mb-12 text-black" style="font-size:1.375rem">“智能化正在改变整个世界,如果你不跟上时代的脚步,那你就会被时代淘汰”</p>
          <p class="text-black" style="font-size:1.375rem"><span class="mr-1">———</span>陈世阳<span class="mx-1">·</span>世阳创始人</p>
          <img src="@/static/icon/back.png" class="cursor-pointer mt-28 mb-4 hidden lg:block transition-all duration-700 transform hover:-rotate-90" @click="goAnchor('#headerTap')" alt="">
        </div>
        <div class="col-md-12 col-lg-8">
          <img src="@/static/images/word.png" class="relative" alt="">
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-3 hidden lg:block">
          <div class="h-32 bg-70 w-px ml-8 mt-10"></div>
          <div class="hidden lg:block">
            <p style="font-size:3.75rem" class="font-bold mt-4 transform -rotate-90 w-14 mb-4 text-black">03</p>
            <p class="text-ABC text-2xl Optima font-bold transform w-14 tracking-widest -rotate-90 text-black">page</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-7 sm:offset-2 text-white">
          <div class="bg-base card-footer w-full">
            <h2 class="text-ABC Optima text-orange font-bold mb-12" style="letter-spacing: .3rem;">Professional </h2>
            <p class="text-3xl mb-12 font-medium">
              共创智能化世界————
            </p>
            <p class="font-medium text-2xl">
              如果您想实现智能化的系统,您可以找我们,我们可以为您实现您的梦想.我们携手与您一起创造智能化的世界
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    circular: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.cooperation {
  width: 100%;
  background-color: #f5f5f5;
  background-size: 100% 51.4375rem;
  margin-top: -5.625rem;
  background-position: center center;
  background-size: cover;
}

.card-footer {
  margin-top: -11.5rem;
  padding: 7.125rem 2.6875rem 8.9375rem 2.6875rem;
}
</style>
