<template>
<!-- 遮罩 -->
<div class=" left-0 right-0 bottom-0 top-0 w-full h-full" @click.stop="handleClose" :class="open?'fixed z-20':''">
  <!-- 本体 -->
  <div class="fixed w-96 h-screen bg-white z-50 top-0 right-0 lg:hidden transition-all duration-700 transform" :class="!open?'x-full opacity-0':'x-0 opacity-100'">
    <ul class="overflow-scroll block h-full">
      <li class="border-b" @click="handleJumpUrl('/')">
        <a href="javascript:void(0);" class="w-full block pl-8 py-3 pr-3 text-2xl hover:text-orange">首页</a>
      </li>
      <li class="border-b">
        <div class="flex items-center pr-6" @click.stop="handleChangeFold(0)">
          <a class="w-full block pl-8 py-3 text-2xl hover:text-orange">智能系统</a>
          <a-icon type="plus"></a-icon>
        </div>
        <div class="transition-all duration-700" :class="!fold && status == 0 ? 'block':'hidden'">
          <ul>
            <li @click="handleJumpUrl(`/smart-detail/${item.id}`)" class="py-3" :class="index == smartList.length - 1?'':'border-b'" v-for="(item,index) in smartList" :key="item.id"><a href="javascript:void(0);" class="pl-16 hover:text-orange">{{item.name}}</a></li>
          </ul>
        </div>
      </li>
      <li class="border-b">
        <div class="flex items-center pr-6" @click.stop="handleChangeFold(1)">
          <a class="w-full block pl-8 py-3 text-2xl hover:text-orange">产品中心</a>
          <a-icon type="plus"></a-icon>
        </div>
        <div class="transition-all duration-700" :class="!fold && status == 1 ? 'block':'hidden'">
          <ul>
            <li @click="handleJumpUrl(`/product/${item.id}`)" class="py-3" :class="index == categoryList.length - 1?'':'border-b'" v-for="(item,index) in categoryList" :key="item.id"><a href="javascript:void(0);" class="pl-16 hover:text-orange">{{item.name}}</a></li>
          </ul>
        </div>
      </li>
      <li class="border-b" @click="handleJumpUrl('/about')">
        <a href="javascript:void(0);" class="w-full block pl-8 py-3 pr-3 text-2xl hover:text-orange">关于我们</a>
      </li>
      <li class="border-b" @click="handleJumpUrl('/support')">
        <a href="javascript:void(0);" class="w-full block pl-8 py-3 pr-3 text-2xl hover:text-orange">技术支持</a>
      </li>
      <li class="border-b" @click="handleJumpUrl('/contact')">
        <a href="javascript:void(0);" class="w-full block pl-8 py-3 pr-3 text-2xl hover:text-orange">加盟合作</a>
      </li>
      <li class="border-b" @click="handleJumpUrl('/news')">
        <a href="javascript:void(0);" class="w-full block pl-8 py-3 pr-3 text-2xl hover:text-orange">新闻中心</a>
      </li>
    </ul>
    <div class="absolute top-8 w-16 h-16 bg-white flex items-center justify-content-center z-50" @click="handleClose" style="left:-4.1rem">
      <a-icon type="close" style="font-size:20px"></a-icon>
    </div>
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'
export default {
  data() {
    return {
      fold: true,
      status: null
    }
  },
  computed: {
    ...mapState({
      smartList: state => state.smartList,
      categoryList: state => state.categoryList
    })
  },
  methods: {
    handleChangeFold(status) {
      this.status == status && this.fold == false ? this.fold = !this.fold : this.fold = false
      this.status = status
    },
    handleClose() {
      this.$store.commit("updateOpen", false)
    },
    handleJumpUrl(url) {
      this.handleClose()
      this.$router.push({
        path: url
      })
      this.status = null
      this.fold = false
    }
  },
}
</script>

<style lang="scss" scoped>
.x-full {
  transform: translateX(calc(100% + 4.1rem));
}

.x-0 {
  transform: translateX(0);
}
</style>
