<template>
<div class="pb-56" :class="bg?'bg-f4':'bg-white mt-56'" id="concatus">
  <div class="container mx-auto">
    <h2 class="text-ABC Optima text-orange font-bold" v-if="!bg" style="letter-spacing: .3rem;">Contact us</h2>
    <h2 class="font-bold mb-12" v-if="!bg" style="font-size:3.75rem">联系我们</h2>
    <div class="mb-24" id="concatusmap">
      <div class="amap-wrapper" style="height:33.4375rem">
        <el-amap class="amap-box" :vid="'amap-vue'" :center="center" zoom="16">
          <el-amap-marker :position="center"></el-amap-marker>
        </el-amap>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <form>
          <div class="form-control">
            <a-icon type="user" class="w-12 h-8 form-label-img left-3 text-90" style="font-size:1.75rem"></a-icon>
            <input placeholder="姓名" class="w-full con-input" v-model="form.name" :style="bg?{background:bg}:{}">
          </div>
          <div class="form-control">
            <a-icon type="phone" class="w-9 h-9 form-label-img left-4 text-90" style="font-size:1.75rem"></a-icon>
            <input placeholder="电话" class="w-full con-input" v-model="form.phone" :style="bg?{background:bg}:{}">
          </div>
          <div class="form-control">
            <a-icon type="mail" class="form-label-img left-5 text-90" style="font-size:1.75rem;width:1.875rem;height:1.875rem"></a-icon>
            <input placeholder="邮箱" v-model="form.email" class="w-full con-input" :style="bg?{background:bg}:{}">
          </div>
          <div class="form-control">
            <a-icon type="edit" class="w-7 h-7 form-label-img left-5 text-90" style="font-size:1.75rem"></a-icon>
            <input placeholder="描述" v-model="form.detail" class="w-full con-input" :style="bg?{background:bg}:{}">
          </div>
          <div class="form-control">
            <button class="bg-yellow w-full py-6 bg-ye text-white text-xl font-medium tracking-widest" @click="handleSubmitForm">立即提交</button>
          </div>
        </form>
      </div>
      <div class="col-lg-6">
        <p class="text-lg text-333 mb-6">时刻铭记质量是世阳智能生存的基石，是客户选择世阳智能的理由。 我们把客户要求与期望准确传递到世阳智能整个价值链，共同构建质量； 我们尊重规则流程，一次把事情做对；我们与客户一起平衡机会与风险，快速响应客户需求，实现可持续发展。</p>
        <img src="@/static/images/mini_qrcode.png" alt="小程序码" class="mb-16">
        <p class="text-xl"><span class="font-medium text-333 tracking-widest">咨询热线：</span><span class="text-5xl DINMedium font-bold text-1f">{{companyInfo.phone}}</span></p>
        <p class="text-xl"><span class="font-medium text-333 tracking-widest">咨询邮箱：</span><a class="text-999 cursor-pointer hover:text-orange" :href="`mailto:${companyInfo.email}`">{{companyInfo.email}}</a></p>
        <p class="text-xl"><span class="font-medium text-333 tracking-widest">地址：</span><span class="text-999">{{companyInfo.address}}</span></p>
        <p class="text-xl"><span class="font-medium text-333 tracking-widest">网址：</span><span class="text-999"><a :href="'http://'+webAddress" class="hover:text-orange" target="_blank">http://{{webAddress}}</a></span></p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {
  saveDemand
} from "@/api"
import {
  emailValidate,
  phoneValidate
} from "@/utils/validate"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/dist/sweetalert2.min.css';
export default {
  props: {
    bg: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      markerPoint: {
        lng: 112.4118,
        lat: 28.539581
      },
      center: [112.405379, 28.533604],
      form: {}
    }
  },
  mounted() {
    if (this.$route.hash) {
      setTimeout(() => {
        this.goAnchor(this.$route.hash)
      }, 500);
    }
  },
  methods: {
    async handleSubmitForm(e) {
      e.preventDefault();
      if (!this.form.name) return Swal.fire({
        icon: "error",
        title: "提示",
        text: "请输入姓名"
      })
      if (!phoneValidate(this.form.phone)) return Swal.fire({
        icon: "error",
        title: "提示",
        text: "请输入正确的手机号码!"
      })
      if (!emailValidate(this.form.email)) return Swal.fire({
        icon: "error",
        title: "提示",
        text: "请输入正确的邮箱地址!"
      })
      const res = await saveDemand(this.form)
      if (res.code == 200) {
        Swal.fire({
          icon: "success",
          title: "提示",
          text: "提交成功"
        })
        this.form = {}
      } else {
        Swal.fire({
          icon: "error",
          title: "提示",
          text: res.message
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.map {
  width: 100%;
  height: 33.4375rem;
}
</style>
