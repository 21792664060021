<template>
<div class="pb-52 bg-f5">
  <div class="cooperation">
    <div class="container pt-28 pb-12">
      <div class="row">
        <div class="col-md-12 col-lg-4">
          <h2 class="text-ABC Optima text-orange font-bold" style="letter-spacing: .3rem;">DESIGN</h2>
          <p class="font-bold mb-16 text-black" style="font-size:3.75rem">专业设计</p>
          <ul>
            <li class="text-22 flex items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              优秀的硬件工程师团队
            </li>
            <li class="text-22 flex items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              优秀的软件工程师团队
            </li>
            <li class="text-22 flex items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              优秀的设计师团队
            </li>
            <li class="text-22 flex items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              优秀的施工安装团队
            </li>
          </ul>
          <img src="@/static/icon/back.png" class="cursor-pointer mt-28 mb-4 hidden lg:block transition-all duration-700 transform hover:-rotate-90" @click="goAnchor('#headerTap')" alt="">
        </div>
        <div class="col-md-12 col-lg-8">
          <img src="@/static/images/cooper-1.png" class="relative h-full" alt="">
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-3 hidden lg:block">
          <div class="h-52 bg-70 w-px ml-8 mt-10"></div>
          <div class="hidden lg:block">
            <p style="font-size:3.75rem" class="font-bold  transform -rotate-90 w-14 mb-4 text-black">01</p>
            <p class="text-ABC text-2xl Optima font-bold transform w-14 tracking-widest -rotate-90 text-black">page</p>
          </div>
        </div>
        <div class="col-md-12 col-lg-7 sm:offset-2 text-white">
          <div class="bg-base card-footer w-full">
            <h2 class="text-ABC Optima text-orange font-bold mb-12" style="letter-spacing: .3rem;">Good team</h2>
            <p class="text-3xl mb-12 font-medium">
              专业设计团队为您量身打造 ————
            </p>
            <p class="font-medium text-2xl">
              我们拥有一支专业的开发队伍,可以为您量身定做智能系统方案,可以自我研发软件系统和硬件设备.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    circular: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.cooperation {
  width: 100%;
  background-size: cover;
  background-position: center center !important;
  background-repeat: no-repeat;
}

.card-footer {
  margin-top: -4.5rem;
  padding: 7.125rem 2.6875rem 8.9375rem 2.6875rem;
}
</style>
