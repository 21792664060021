import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      companyName: state => state.companyName,
      RecordNo: state => state.RecordNo,
      year: state => state.year,
      webAddress: state => state.webAddress,
      views: state => state.views,
      companyInfo: state => state.companyInfo,
      open: state => state.open
    })
  },
  methods: {
    goAnchor (selector) {
      document.querySelector(selector).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    },
    print(){
      console.log(2);
    },
    _backTop() {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      document.body.style.marginTop = -scrollTop + 'px';
      document.body.scrollTop = 0;
      document.body.style.transition = 'all 1s ease-in-out';
      document.body.style.marginTop = 0;
      setTimeout(function () {
        document.body.style.transition = 'none';
      }, 1000);
    }
  },
}