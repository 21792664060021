<template>
<div class="pb-52">
  <div class="cooperation">
    <div class="container pt-44 pb-24">
      <div class="row">
        <div class="col-md-12 col-lg-8">
          <img src="@/static/images/quality.png" class="relative" alt="">
        </div>
        <div class="col-md-12 col-lg-4 mt-10 sm:mt-0">
          <h2 class="text-ABC Optima text-orange font-bold mt-5 lg:mt-0" style="letter-spacing: .3rem;">Good service</h2>
          <p class="font-bold mb-16 text-black" style="font-size:3.75rem">优质服务</p>
          <ul>
            <li class="text-22 flex w-full items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              为您提供一年产品包换的服务
            </li>
            <li class="text-22 flex w-full items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              为您量身定做智能系统方案
            </li>
            <li class="text-22 flex w-full items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              为您提供上门安装服务
            </li>
            <li class="text-22 flex w-full items-center mb-8 text-black">
              <img src="@/static/icon/li-icon.png" class="w-3 h-3 mr-8" alt="">
              为您终身提供维修服务
            </li>
          </ul>
          <img src="@/static/icon/back.png" @click="goAnchor('#headerTap')" class="cursor-pointer mt-28 float-right transform -rotate-180 hidden lg:block transition-all duration-300 hover:-rotate-90" alt="">
        </div>
      </div>
    </div>
  </div>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12 col-lg-7 text-white offset-lg-2">
          <div class="bg-base card-footer w-full">
            <h2 class="text-ABC Optima text-orange font-bold mb-12" style="letter-spacing: .3rem;">High quality service</h2>
            <p class="text-3xl mb-12 font-medium">
              为您提供优质的服务————
            </p>
            <p class="font-medium text-2xl">我们拥有一支专业的售后服务团队
            </p>
          </div>
        </div>
        <div class="col-md-12 col-lg-3 flex items-center justify-end w-full pt-36">
          <div class="hidden lg:block af">
            <p style="font-size:3.75rem" class="font-bold  transform -rotate-90 w-14 mb-4 text-black">02</p>
            <p class="text-ABC text-2xl Optima font-bold transform w-14 tracking-widest -rotate-90 text-black">page</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    circular: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style lang="scss" scoped>
.cooperation {
  width: 100%;
  background-size: 100% 51.4375rem;
  background-color: #f5f5f5;
  margin-top: -5.625rem;
  background-position: center center;
  background-size: cover;
}

.card-footer {
  margin-top: -11.5rem;
  padding: 7.125rem 2.6875rem 8.9375rem 2.6875rem;
  min-height: 31.625rem;
}

.af {
  position: relative;

  &::after {
    top: -9rem;
    content: "";
    left: 50%;
    position: absolute;
    width: 200%;
    height: 17rem;
    border-left: 1px solid #707070;
    -webkit-transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -ms-transform-origin: 0 0;
    -o-transform-origin: 0 0;
    transform-origin: 0 0;
    -webkit-transform: scale(0.5, 0.5);
    -ms-transform: scale(0.5, 0.5);
    -o-transform: scale(0.5, 0.5);
    transform: scale(0.5, 0.5);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}
</style>
