import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "@/assets/css/tailwind.css"
import "@/assets/css/animation.css"
import "@/assets/css/global.scss"
import "@/assets/css/bootstrap-grid.css"
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueAMap from 'vue-amap';
import { Icon } from "ant-design-vue"
import StoreMixin from "@/mixins"
Vue.mixin(StoreMixin)
Vue.use(Icon)
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  // 高德key
  key: '607ddc131bade72f00f7ddc78fbe0f68', 
  plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.MarkerClusterer'],
  v: '1.4.15',
  uiVersion: '1.0.11'
})
Vue.config.productionTip = false
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
