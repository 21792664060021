<template>
<div id="app">
  <div v-show="!loading">
    <Header />
    <MobileNav />
    <router-view />
    <Footer />
  </div>
  <transition name="fade">
    <Loading :loading="loading" />
  </transition>
</div>
</template>

<script>
import Header from "@/components/Home/Header/Header.vue"
import Footer from '@/components/footer/footer.vue'
import MobileNav from '@/components/mobile-nav/mobile-nav.vue'
import Loading from "@/components/loading/loading.vue"
import {
  _isMobile
} from "@/utils"
import {
  getSmartList,
  getCategory
} from "@/api"
export default {
  components: {
    Header,
    Footer,
    MobileNav,
    Loading
  },
  data() {
    return {
      loading: true
    }
  },
  created() {
    console.log(process.env.NODE_ENV);
    let phone = _isMobile()
    // 移动端
    if (phone) {
      // 判断是ipad还是手机
      phone[0] == "iPad" ? this.changeSystem('12px', 3) : this.changeSystem('12px', 1)
    } else {
      // pc监听宽度
      window.onresize = () => {
        return (() => {
          let width = document.body.clientWidth
          if (width < 768) {
            this.changeSystem('12px', 1)
          } else if (width < 1024) {
            this.changeSystem('12px', 3)
          } else if (width < 1920) {
            this.changeSystem('16px', 3)
          }
        })()
      }
    }
    // 请求智能系统列表
    this.fetchSmartList()
    this.fetchProductCategory(1, 1)
  },
  methods: {
    async fetchSmartList() {
      const res = await getSmartList()
      this.$store.commit('updateSmartList', res.data)
    },
    async fetchProductCategory(categoryNum, status) {
      const res = await getCategory({
        categoryNum,
        status
      })
      this.$store.commit('updateCategoryList', res.data)
      setTimeout(() => {
        this.loading = false
      }, 100);
    },
    changeSystem(size, views) {
      console.log(views, 'views');
      let html = document.querySelector('.html_class')
      html.style.fontSize = size
      this.$forceUpdate()
      this.$store.commit('updateSwiperViews', views)
    }
  },
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
}

#app,
body,
html {
  width: 100%;
}

html {
  font-family: -apple-system, 'Microsoft YaHei UI', 'Microsoft YaHei', DengXian, SimSun, 'Segoe UI', Tahoma, Helvetica, sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.ql-container {
  font-size: 1.0625rem !important;
}
</style>
