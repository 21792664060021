<template>
<div class="bg-base w-full text-white h-28 relative" id="headerTap">
  <div class="px-4 lg:px-0 md:container w-full mx-auto h-full flex items-center justify-between lg:justify-start lg:text-sm xl:text-lg" style="line-height:7rem">
    <img class="header-logo cursor-pointer xl:-ml-4 lg:-ml-12 md:-ml-0" src="@/static/images/header-logo.png" alt="">
    <div class="flex items-center w-full justify-between">
      <nav class="items-center justify-between hidden lg:flex h-full bg-white-800 text-gray-500 pr-4 pl-3">
        <div class="flex items-center h-full">
          <ul class="ml-4 flex items-stretch h-full">
            <li>
              <a href="javascript:void(0);" @click="handleJumpUrl('/')" class="hover:color:red text-white hover:text-orange" :class="page == '/'?'text-orange':''">首页</a>
            </li>
            <li>
              <a class="text-white hover:text-orange pl-8" :class="smartActive?'text-orange':''" style="">智能系统</a>
              <ul class="z-2">
                <li v-for="item in smartList" :key="item.id">
                  <a @click="handleViewDetail(item.id)" href="javascript:void(0);" class="text-white hover:text-orange lg:text-sm xl:text-base">{{item.name}}</a>
                </li>
              </ul>
            </li>
            <li>
              <a class="text-white hover:text-orange pl-8" :class="productActive? 'text-orange':''" style="">产品中心</a>
              <ul class="z-2">
                <li v-for="item in categoryList" :key="item.id">
                  <a href="javascript:void(0);" @click="handleJumpUrl('/product',item.id)" class="text-white hover:text-orange font-light lg:text-sm xl:text-base">{{item.name}}</a>
                </li>
              </ul>
            </li>
            <li>
              <a href="javascript:void(0);" @click="handleJumpUrl('/about')" class="hover:color:red text-white hover:text-orange pl-8" :class="page == '/about'?'text-orange':''">关于我们</a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="handleJumpUrl('/support')" class="hover:color:red text-white hover:text-orange pl-8" :class="page == '/support'?'text-orange':''">技术支持</a>
            </li>
            <li>
              <a href="javascript:void(0);" @click="handleJumpUrl('/contact')" class="hover:color:red text-white hover:text-orange pl-8" :class="page == '/contact'?'text-orange':''">加盟合作</a>
            </li>
            <li class="relative">
              <a href="javascript:void(0);" @click="handleJumpUrl('/news')" class="hover:color:red text-white hover:text-orange pl-8" :class="page == '/news'?'text-orange':''">新闻中心</a>
              <img src="@/static/images/news_tags.png" class="news_tags md:w-6 md:h-3 lg:w-6 lg:h-3 lg:-right-7 xl:w-12 xl:h-6 xl:-right-14" alt="">
            </li>
          </ul>
        </div>
      </nav>
      <div class="hidden lg:flex">
        <div class="flex items-center cursor-pointer mr-4 xl:mr-8" @click="handleJumpUrl('/product/0')">
          <a-icon type="search" />
          <span class="ml-2">
            <a href="javascript:void(0);" class="hover:text-white">搜索</a>
          </span>
        </div>
        <div class="flex items-center cursor-pointer mr-2 xl:mr-8" @click="handleJumpUrl('/contact/#concatusmap')">
          <a-icon type="message" /><span class="ml-2">
            <a href="javascript:void(0);" class="hover:text-white">在线留言</a>
          </span>
        </div>
      </div>
    </div>
    <!--  -->
    <span class="lg:hidden cursor-pointer" @click="handleOpen">
      <a-icon type="menu" style="font-size:2.5rem" />
    </span>
    <!-- mobile nav -->
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'
export default {
  watch: {
    $route(to, from) {
      this.page = this.$route.path
      if (this.page.indexOf('/smart-detail') == 0) {
        this.smartActive = true
        this.productActive = false
      } else if (this.page.indexOf('/product') == 0) {
        this.smartActive = false
        this.productActive = true
      } else {
        this.smartActive = false
        this.productActive = false
      }
    }
  },
  data() {
    return {
      page: '',
      // 智能系统
      smartActive: false,
      // 产品中心
      productActive: false,
    }
  },
  computed: {
    ...mapState({
      smartList: state => state.smartList,
      categoryList: state => state.categoryList
    })
  },
  methods: {
    handleViewDetail(id) {
      this.$router.push({
        path: `/smart-detail/${id}`
      })
    },
    handleJumpUrl(url, id) {
      id ? this.$router.push({
        path: url + `/${id}`
      }) : this.$router.push({
        path: url
      })
    },
    handleOpen() {
      this.$store.commit("updateOpen", true)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.bg-base {
  background: $bg-base;
}

.header-logo {
  width: 16.3125rem;
  height: 10.9375rem;
  z-index: 2;
}

nav a {
  display: flex;
  height: 100%;
  align-items: center;
  // justify-content: center;
  text-align: center;
}

nav a.active {
  // min-width: 130px;
}

nav li>ul {
  background-color: $bg-base;
  position: absolute;
  overflow: hidden;
  text-align: center;
  padding: 0 17px;
  opacity: 0;
  // max-width: 130px;
  min-width: 130px;
  visibility: hidden;
  transition: ease all 0.3s;
}

nav li>ul a {
  // font-size: 16px;
  line-height: 22px;
  padding: 15px 0;
}

nav li:hover>ul {
  opacity: 1;
  visibility: visible;
}

.z-2 {
  z-index: 2;
}

.news_tags {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
