<template>
<div class="bg-f5 pt-16 pb-32">
  <div class="container mx-auto pb-52 ">
    <h2 class="text-ABC Optima text-orange font-bold" style="letter-spacing: .3rem;">Smart</h2>
    <h2 class="font-bold mb-12 text-black" style="font-size:3.75rem">智能管理系统</h2>
    <div class="recommendPage">
      <swiper :options="swiperOption" ref="mySwiper1" :key="swiperKey">
        <swiper-slide v-for="(item,index) in smartList" :key="item.id">
          <div class="text-black s-list" :data-id="item.id">
            <div class="flex justify-center mb-16 ">
              <a-icon :type="item.icon || 'setting'" :style="{ fontSize: '6.5rem' }"></a-icon>
            </div>
            <p class="text-center text-xl mb-8 text-black">{{item.name}}</p>
            <p class="text-xl mb-8 text-666 text-left text-cut1">{{item.remark}}</p>
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</div>
</template>

<script>
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import {
  mapState
} from "vuex"
export default {
  components: {
    swiper,
    swiperSlide
  },
  data() {
    return {
      swiperKey: ""
    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper1.swiper;
    },
    ...mapState({
      smartList: state => state.smartList
    }),
    swiperOption() {
      return {
        init: false,
        loop: true,
        slidesPerView: this.views,
        spaceBetween: 0,
        slidesPerGroup: 1,
        speed: 700,
        centeredSlides: true,
        animating: false,
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.swiper.init()
    }, 500);
  },
  methods: {
    handleChangeSwiper(e) {},
    clickSmart(el) {
      let id = el.dataset.id
      console.log(id);
      if (id) {
        this.$router.push({
          path: `/smart-detail/${id}`
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 30.5625rem;
}

.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  color: #fff;
  text-align: center;
}

.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background: rgba(255, 255, 255, 0.99);
  text-align: center;
  font-size: 1.125rem;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  transition: 300ms;
  transform: scale(0.8);
}

::v-deep .swiper-slide-active,
::v-deep .swiper-slide-duplicate-active {
  transform: scale(0.9);
  box-shadow: 0px 0.1875rem 2rem rgba(245, 188, 17, 0.31);
}

.swiper-slide.swiper-slide-active>div>div {
  color: #f5bc11;
  background: #fff;
}

.swiper-slide .swiper-slide-active {}

.s-list {
  padding: 3.3125rem 3rem 2.625rem 3rem;
}

.text-cut1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 6;
}
</style>
