<template>
<div class="pt-10 bg-base text-white">
  <div class="container mx-auto">
    <div class="row pb-24">
      <div class="col-lg-3 col-6">
        <p class="text-2xl font-medium tracking-widest mb-8">产品中心</p>
        <ul>
          <li @click="handleJumpUrl(`/product/${item.id}`)" class="text-ba cursor-pointer text-lg font-normal mb-4 hover:text-orange" v-for="item in categoryList" :key="item.id">{{item.name}}</li>
        </ul>
      </div>
      <div class="col-lg-3 col-6">
        <p class="text-2xl font-medium tracking-widest mb-8">新闻中心</p>
        <ul>
          <li @click="handleJumpUrl('/news')" class="text-ba cursor-pointer text-lg font-normal mb-4" v-for="item in 1" :key="item">新闻中心</li>
        </ul>
      </div>
      <div class="col-lg-3 col-6">
        <p class="text-2xl font-medium tracking-widest mb-8">合作加盟</p>
        <ul>
          <li @click="handleJumpUrl('/contact')" class="text-ba cursor-pointer text-lg font-normal mb-4" v-for="item in 1" :key="item">合作加盟</li>
        </ul>
      </div>
      <div class="col-lg-3 col-6">
        <p class="text-2xl font-medium tracking-widest mb-8">关于我们</p>
        <ul>
          <li @click="handleJumpUrl('/about')" class="text-ba cursor-pointer text-lg font-normal mb-4">品牌介绍</li>
        </ul>
      </div>
      <!-- <div class="col-lg-8 col-md-6 offset-lg-2 ">
        <p class="text-xl font-bold tracking-widest mb-4">订阅行业新闻</p>
        <div class="row">
          <div class="col-lg-8">
            <input type="text" class="w-full mb-4 lg:mb-0 pl-16 bg-transparent text-xl  font-medium  tracking-widest text-6e" style="height:4.75rem;border: 1px solid #8D93AD;" placeholder="电子邮件地址*">
          </div>
          <div class="col-lg-4">
            <button class="bg-yellow w-full py-6 bg-ye text-white text-xl font-medium tracking-widest">立即提交</button>
          </div>
        </div>
      </div> -->
      <div class="col-lg-2 col-md-9 offset-lg-9 lg:-mt-36 xl:-mt-36">
        <button class="bg-transparent border-fc w-full py-6 text-white text-xl font-medium tracking-widest back-btn" @click="goAnchor('#headerTap')">返回顶部</button>
      </div>
    </div>
  </div>
  <div class="w-full h-px bg-white"></div>
  <div class="container mx-auto mt-6 pb-6 text-center">
    <p class="text-6e text-xl font-medium">版权所有 © {{year}} {{companyName}} {{webAddress}} 保留所有权利 .</p>
    <p class="text-6e text-xl font-medium"><a href="https://beian.miit.gov.cn" target="_blank">{{RecordNo}}</a></p>
  </div>
</div>
</template>

<script>
import {
  mapState
} from 'vuex'
export default {
  computed: {
    ...mapState({
      categoryList: state => state.categoryList
    })
  },
  methods: {
    handleJumpUrl(url) {
      this.$router.push({
        path: url
      })
      this._backTop()
    },
  },
}
</script>

<style lang="scss" scoped>
.back-btn {
  color: #fc4c4c;
  border: 1px solid #f24a4a;
  margin-top: 2.75rem;
}
</style>
