import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/index.vue'
import store from "@/store"
Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: `${store.state.companyName}`
    }
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      title: '关于我们'
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    meta: {
      title: '加盟合作'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/cooperation.vue')
  },
  {
    path: '/smart-detail/:id',
    name: 'smart-detail',
    meta: {
      title: '智能详情'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/smart-detail.vue')
  },
  {
    path: '/product/:id',
    name: 'product',
    meta: {
      title: '产品中心'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/product.vue')
  },
  {
    path: '/product-detail/:id',
    name: 'product-detail',
    meta: {
      title: '产品详情'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/product-detail.vue')
  },
  {
    path: '/support',
    name: 'support',
    meta: {
      title: '技术支持'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/support.vue')
  },
  {
    path: '/news',
    name: 'news',
    meta: {
      title: '新闻中心'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/news.vue')
  },
  {
    path: '/news-detail/:id',
    name: 'news-detail',
    meta: {
      title: '新闻详情'
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/news-detail.vue')
  },
  {
    path:"*",
    name:"404",
    meta:{
      title:"404"
    },
    component: () => import(/* webpackChunkName: "cooperation" */ '../views/404.vue')
  }
]



const router = new VueRouter({
  mode: process.env.NODE_ENV == 'production' ? 'history' :'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, form, next) => {
  if (to.meta.title) document.title = to.meta.title + ' | 湖南世阳智能科技有限公司'
  next()
})
export default router
