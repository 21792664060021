<template>
<div class="home">
  <HomeSwiper :list="list" />
  <div class="bg">
    <div class="ye-ball bg-orange cursor-pointer text-xl transition-all duration-700" @click="goAnchor('#service')">
      查看解决方案
    </div>
  </div>
  <Service />
  <div class="cooperation"></div>
  <div class="ye-ball1 bg-orange cursor-pointer text-xl" @click="goAnchor('#concatus')">
    合作加盟
  </div>
  <div class="ball1-bg"></div>
  <Cooperation :circular="true" />
  <CooperationRight />
  <Cooperation1 />
  <VisualData />
  <Smart />
  <ConcatUs />
</div>
</template>

<script>
import {
  getSwiper
} from "@/api"
import HomeSwiper from "@/components/Home/home-swiper/home-swiper.vue"
import Service from "@/components/Home/Service/Service.vue"
import Cooperation from "@/components/Home/Cooperation/Cooperation.vue"
import Cooperation1 from "@/components/Home/Cooperation/Cooperation1.vue"
import CooperationRight from "@/components/Home/Cooperation/CooperationRight.vue"
import VisualData from "@/components/Home/VisualData/VisualData.vue"
import Smart from "@/components/Home/Smart/Smart.vue"
import ConcatUs from "@/components/Home/ConcatUs/ConcatUs.vue"
export default {
  name: 'Home',
  components: {
    HomeSwiper,
    Service,
    Cooperation,
    CooperationRight,
    Cooperation1,
    VisualData,
    Smart,
    ConcatUs
  },
  data() {
    return {
      list: []
    }
  },
  created() {
    this.fetchSwiper()
  },
  methods: {
    async fetchSwiper() {
      const res = await getSwiper(1)
      this.list = res.data.records
    }
  },
}
</script>

<style lang="scss" scoped>
.ye-ball {
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 10.75rem;
  line-height: 10.75rem;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  position: relative;
  margin-top: 24px;
  z-index: 2;
  box-shadow: 0.4375rem 0.4375rem 1.1875rem rgba(245, 188, 17, 0.47);
}

.ye-ball:hover {
  box-shadow: 0.87rem 0.87rem 1.1875rem rgba(245, 188, 17, 0.47);
}

.ye-ball1 {
  width: 10.75rem;
  height: 10.75rem;
  border-radius: 10.75rem;
  line-height: 10.75rem;
  text-align: center;
  color: #fff;
  margin: 0 auto;
  margin-top: -5.625rem;
  position: relative;
  z-index: 2;
  box-shadow: 0.4375rem 0.4375rem 1.1875rem rgba(245, 188, 17, 0.47);
}

.bg {
  width: 16.75rem;
  position: absolute;
  left: 50%;
  height: 7rem;
  margin-top: -7rem;
  transform: translateX(-50%);
  background-image: url("../static/images/radius_bg_white.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  z-index: 3;
}

.cooperation {
  width: 100%;
  height: 50rem;
  background-size: 100% 100%;
  background-image: url("../static/images/cooperation_bg.png");
  background-position: center center;
  background-size: cover;
}

.ball1-bg {
  height: 7.5rem;
  background-color: #f5f5f5;
  margin-top: -5.625rem;
  background-image: url("../static/images/radius_bg_white.png");
  background-size: 16.75rem 7.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(180deg)
}
</style>
