<template>
<div id="service" class="container mx-auto pb-48 mt-56">
  <h2 class="text-ABC Optima text-orange font-bold" style="letter-spacing: .3rem;">The solution</h2>
  <h1 class="font-bold mb-11" style="font-size:3.75rem">服务与解决方案</h1>
  <div>
    <div class="flex items-center justify-between row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <img src="@/static/images/smart-1.png" style="width:17.0625rem;height:16rem;margin:0 auto" alt="">
        <p class="font-normal text-3xl text-center">智能酒店 | 管理系统</p>
        <p class="text-666 text-center text-xl" style="letter-spacing: .28rem;">Management system</p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <img src="@/static/images/smart-2.png" style="width:17.0625rem;height:16rem;margin:0 auto" alt="">
        <p class="font-normal text-3xl text-center">智能车位 | 管理系统</p>
        <p class="text-666 text-center text-xl" style="letter-spacing: .28rem;">Management system</p>
      </div>
    </div>
    <div class="service-bg font-normal">
      您是不是梦想可以一句话控制家里所有电器,
      我们可以帮您实现您的梦想！！
    </div>
    <div class="flex items-center justify-between row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <img src="@/static/images/smart-3.png" style="width:17.0625rem;height:16rem;margin:0 auto" alt="">
        <p class="font-normal text-3xl text-center">智能点滴 | 管理系统</p>
        <p class="text-666 text-center text-xl" style="letter-spacing: .28rem;">Management system</p>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4">
        <img src="@/static/images/smart-4.png" style="width:17.0625rem;height:16rem;margin:0 auto" alt="">
        <p class="font-normal text-3xl text-center">智能景区 | 管理系统</p>
        <p class="text-666 text-center text-xl" style="letter-spacing: .28rem;">Management system</p>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.service-bg {
  width: 34.5625rem;
  align-items: center;
  height: 18.1875rem;
  background-image: url("../../../static/images/service_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.625rem;
  padding: 0 1.625rem;
  margin: -1.25rem auto;
}

@media screen and(max-width:976px) {
  .service-bg {
    width: 17.2813rem;
    height: 9.0938rem;
    font-size: 0.8125rem;
    margin: 1.25rem auto;
    padding: 1.25rem;
  }
}

@media screen and(max-width:967px) {
  .service-bg {
    width: 23.2813rem;
    height: 14.0938rem;
    font-size: 0.8125rem;
    margin: 1.25rem auto;
    padding: 2.25rem;
  }
}
</style>
