import Vue from 'vue'
import Vuex from 'vuex'
import * as dayjs from 'dayjs'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginStatus: false,
    user: {},
    token: "",
    views: 3,
    companyName: "世阳智能",
    // 备案号
    RecordNo: "京ICP备15027046号-8",
    year: dayjs().year(),
    webAddress: "syang.wang",
    companyConfig: [
      {
        icon: "http://image.syang.wang/2021-10-26/84a4583e-2024-42de-b12c-45940e04406d.png",
        label: "办公地点",
        detail: "湖南省益阳市高新区中南科技创新产业园4栋",
        class: "44px"
      },
      {
        icon: "http://image.syang.wang/2021-10-26/cff6aafb-41c4-4ce1-a09a-d9257b35c7f2.png",
        label: "联系电话",
        detail: "086+0737-3339938",
        class: "55px"
      },
      {
        icon: "http://image.syang.wang/2021-10-26/918ea388-dead-432a-b1bd-ed3aaa14f870.png",
        label: "关于邮箱",
        detail: "bm@syang.wang",
        class: "60px"
      },
      {
        icon: "http://image.syang.wang/2021-10-26/72c945b8-9c5c-49d9-900d-6210d066b6a3.png",
        label: "工作时间",
        detail: "上午 9 点 - 下午 6 点      周末休息",
        class: "60px"
      },
    ],
    companyInfo: {
      phone: "0737-3339938",
      email: "bm@syang.wang",
      address: "湖南省益阳市高新区中南科技创新产业园4栋",
      webAddress: "http://syang.wang"
    },
    smartList: [],
    categoryList: [{
      id: "0",
      name: "所有产品"
    }],
    open: false
  },
  mutations: {
    updateSwiperViews (state, data) {
      console.log(data);
      state.views = data
    },
    updateSmartList (state, data) {
      if (data) state.smartList = data
    },
    updateCategoryList (state, data) {
      if (data) state.categoryList.push(...data)
    },
    updateOpen (state, data) {
      state.open = data
    }
  },
  actions: {
  },
  modules: {
  }
})
