<template>
<div class="preloader transition-all duration-700" :style="loading?'display:flex':'display:none !important'">
  <div class="loader">加载中...</div>
</div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.preloader {
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloader .loader {
  font-size: 10px;
  text-indent: -9999em;
  width: 100px;
  height: 100px;
  position: absolute;
  left: calc(50% - 50px);
  top: calc(50% - 50px);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: #ef233c;
  background: -o-linear-gradient(left, #ef233c 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-gradient(linear,
      left top,
      right top,
      color-stop(10%, #ef233c),
      color-stop(42%, rgba(255, 255, 255, 0)));
  background: linear-gradient(to right,
      #ef233c 10%,
      rgba(255, 255, 255, 0) 42%);
  -webkit-animation: load3 1.3s infinite linear;
  animation: load3 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.preloader .loader:before {
  width: 50%;
  height: 50%;
  background: #ef233c;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.preloader .loader:after {
  background: #fff;
  width: 85%;
  height: 85%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
