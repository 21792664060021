<template>
<div class="recommendPage">
  <swiper :options="swiperOption" ref="mySwiper">
    <swiper-slide v-for="(item,index) in list" :key="item.id">
      <div class="h-full swiper-img flex flex-col items-center justify-center relative" :style="{backgroundImage:`url(${item.backgroundImage})`}">
        <div class="droper"></div>
        <h1 class="font-bold tracking-widest text-white px-5 text-5xl z-10 leading-tight" :class="currentIndex == index + 1? 'animation-fade':''" :style="[{animationDelay:.1+'s'}]">{{item.title}}</h1>
        <p class="text-2xl pt-8 pb-1 text-white z-10" :class="currentIndex == index + 1? 'animation-fade':''" :style="[{animationDelay:.3+'s'}]">您是不是梦想可以一句话控制家里所有电器</p>
        <p class="text-2xl z-10" :class="currentIndex == index + 1? 'animation-fade':''" :style="[{animationDelay:.5+'s'}]">我们可以帮您实现您的梦想</p>
      </div>
    </swiper-slide>
    <div class="swiper-pagination" slot="pagination"></div>
  </swiper>
</div>
</template>

<script>
// 引入插件
import {
  swiper,
  swiperSlide
} from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide
  },
  props: {
    list: {
      type: Array,
      default: []
    }
  },
  data() {
    return {
      currentIndex: 1,
      swiperOption: {
        loop: true,
        init: false,
        direction: 'vertical',
        speed: 700,
        spaceBetween: 5,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true //允许分页点击跳转
        },
        on: {
          slideChange: () => {
            this.currentIndex = this.swiper.activeIndex
            console.log(this.currentIndex);
          }
        }
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  mounted() {
    setTimeout(() => {
      this.swiper.init()
      console.log(this.swiper.activeIndex);
    }, 800);
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    console.log("this is current swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/color.scss";

.recommendPage .swiper-container {
  position: relative;
  width: 100%;
  height: 49.1875rem;
}

.recommendPage .swiper-container .swiper-slide {
  width: 100%;
  color: #fff;
  text-align: center;
}

::v-deep .swiper-pagination-bullet {
  width: 0.875rem;
  height: 0.875rem;
  opacity: 0.7;
  background: #fff;
}

::v-deep .swiper-pagination-bullet-active {
  background: $bg-yellow;
}

::v-deep .swiper-container-vertical>.swiper-pagination-bullets {
  right: 6%;
}

.swiper-img {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  width: 100%;
  position: relative;
  background-position: center center;
  background-size: cover;
}

.ls-5 {
  letter-spacing: 5rem;
}

.droper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(62, 62, 62, .4);
}

@media (max-width: 1240px) {
  .ls-5 {
    letter-spacing: 3rem;
  }
}

@media (max-width: 640px) {
  .ls-5 {
    letter-spacing: 0.2rem;
  }
}
</style>
