<template>
<div class="container mx-auto pb-52">
  <h2 class="text-ABC Optima text-orange font-bold" style="letter-spacing: .3rem;">Visual data</h2>
  <h2 class="font-bold mb-12" style="font-size:3.75rem">可视化数据</h2>
  <div class="row">
    <div class="col-lg-4 text-center" v-for="(item,index) in config" :key="index">
      <img :src="item.img" style="margin:0 auto" alt="">
      <h1 class="font-bold text-current text-06 mb-8" style="font-size:5.3125rem">
        <countTo :startVal='startVal' :endVal='item.num' :suffix="item.perfixer" :duration='3000'></countTo>
      </h1>
      <div style="width:167px;margin:0 auto">
        <div class="label_w font-medium text-xl text-black">{{item.label}}</div>
        <div class="label_w text-666">{{item.en}}</div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import countTo from 'vue-count-to';
export default {
  components: {
    countTo
  },
  data() {
    return {
      startVal: 0,
      value: 8564,
      config: [{
          num: 8564,
          label: "服务的用户数量",
          en: "The number of users",
          img: "http://image.syang.wang/2021-10-28/24378092-76a0-4627-b878-e5310ae1627e.png",
          perfixer: ""
        }, {
          num: 100,
          label: "加盟的代理商",
          en: "Franchised agent",
          img: "http://image.syang.wang/2021-10-28/57fe9dc0-b7b3-4ffe-95e7-f8e498775a9a.png",
          perfixer: "k"
        },
        {
          num: 6145,
          label: "产品使用数量",
          en: "Quantity of product used",
          img: "http://image.syang.wang/2021-10-28/0e68b49f-d149-42f9-9fba-a41e5e1e982e.png",
          perfixer: ""
        }
      ]

    }
  },
  computed: {

  }
}
</script>

<style lang="scss" scoped>
.label_w {
  width: 100%;
  height: 1.75rem;

  &::after {
    width: 100%;
    display: inline-block;
    content: "";
  }
}
</style>
