import request from "@/utils/request";
export const getSwiper = (type) => {
  return request({
    url: "/api/cms/swiper/custom-page",
    method: "get",
    params: {
      type
    }
  })
}
// 请求所有智能系统
export const getSmartList = () => {
  return request({
    url: "/api/pms/smartsystem/list",
    method: "get"
  })
}
// 系统详情
export const getSmartDetail = (id) => {
  return request({
    url: "/api/pms/smartsystem/detail",
    method: "get",
    params: {
      id
    }
  })
}
export const getCategory = (params) => {
  return request({
    url: "/api/pms/category/list",
    method: "get",
    params: params
  })
}

export const getCategoryById = (current, size, params) => {
  return request({
    url: "/api/pms/product/custom-page",
    method: "get",
    params: {
      ...params,
      current,
      size
    }
  })
}
export const getProductDetail = (id) => {
  return request({
    url: "/api/pms/product/detail",
    method: "get",
    params: {
      id
    }
  })
}
export const getArticle = (current, size, params) => {
  return request({
    url: "/api/cms/article/custom-page",
    method: "get",
    params: {
      ...params,
      current,
      size
    }
  })
}
export const getNewsDetail = (id) => {
  return request({
    url: "/api/cms/article/detail",
    method: "get",
    params: {
      id
    }
  })
}
// 留言
export const saveDemand = (data) => {
  return request({
    url: "/api/cms/demand/save",
    method: "post",
    data
  })
}